import { css } from '@emotion/react';

export const h1 = css`
    font-size: 2.25rem;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 2.8rem;
    }

    @media (min-width: 1600px) {
        font-size: 4rem;
    }
`;

export const h2 = css`
    font-size: 1.75rem;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }

    @media (min-width: 1600px) {
        font-size: 2.45rem;
    }
`;

export const h3 = css`
    font-size: 1.31rem;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 1.37rem;
    }

    @media (min-width: 1600px) {
        font-size: 1.5rem;
    }
`;
