import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import useMountTransition from '../../hooks/useMountTransition';
import down from '../../images/down-arrow.png';
import NavBtn from '../Buttons/NavBtn';
import { PlainBtn } from './../Buttons/Plain';
import { NavLink, NavLinkBtn, navLinks, subnavLinks } from './NavLinks';

const Nav = styled.div`
    background: ${({ theme }) => theme.gradient};
    padding: 5px 16px;
    width: 300px;
    max-width: 90vw;
    height: 600px;
    z-index: 50;
    position: absolute;
    right: 0px;
    top: 80px;
    box-shadow: 0px 32px 64px rgba(33, 163, 185, 0.2);
    border-radius: 32px 0px 32px 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    opacity: ${({ navOpen, hasNavTransitionedIn }) => (navOpen && hasNavTransitionedIn ? 1 : 0)};
    transition: opacity 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    @media (max-height: 720px) {
        height: 510px;
    }
`;

const Bg = styled.div`
    ::before {
        opacity: ${({ navOpen, hasNavTransitionedIn }) =>
            navOpen && hasNavTransitionedIn ? 0.3 : 0};
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 40;
        background: #031c20;
        transition: opacity 0.3s ease-in-out;
    }
`;

export default function MobNav({ subnav, setSubnav }) {
    const [navOpen, setNavOpen] = useState(false);
    const hasNavTransitionedIn = useMountTransition(navOpen, 300);

    const handleNav = () => {
        if (subnav) setSubnav(false);
        else setNavOpen(navOpen => !navOpen);
    };

    const closeNav = () => {
        setNavOpen(false);
        setSubnav('');
    };

    const renderedLinks = subnav ? subnavLinks[subnav] : navLinks;

    return (
        <div
            css={css`
                position: relative;

                @media (min-width: 1024px) {
                    display: none;
                }
            `}
        >
            <NavBtn navOpen={navOpen} subnav={subnav} handleNav={handleNav} />

            {(navOpen || hasNavTransitionedIn) && (
                <>
                    <Nav navOpen={navOpen} hasNavTransitionedIn={hasNavTransitionedIn}>
                        {renderedLinks.map((link, i) =>
                            link.subMenu ? (
                                <NavLinkBtn
                                    as={PlainBtn}
                                    key={i}
                                    onClick={() =>
                                        setSubnav(subnav => (subnav === link.text ? '' : link.text))
                                    }
                                >
                                    {link.text}
                                    <img src={down} alt="braces menu" />
                                </NavLinkBtn>
                            ) : (
                                <NavLink subnav={subnav} as={Link} to={link.link} key={i}>
                                    {link.text}
                                </NavLink>
                            )
                        )}
                    </Nav>

                    <Bg
                        onClick={closeNav}
                        navOpen={navOpen}
                        hasNavTransitionedIn={hasNavTransitionedIn}
                    />
                </>
            )}
        </div>
    );
}
