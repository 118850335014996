import { css } from '@emotion/react';
import React from 'react';
import close from '../../images/close.png';
import arrow from '../../images/left-arrow.png';
import menu from '../../images/menu.png';
import { CircleBtn } from './Circle';

export default function NavBtn({ navOpen, subnav, handleNav }) {
    return (
        <CircleBtn
            onClick={handleNav}
            css={css`
                z-index: 50;
                display: grid;
            `}
            active={navOpen}
        >
            <img
                src={menu}
                alt="open menu"
                width="26"
                height="26"
                css={css`
                    grid-area: 1/1;
                    opacity: ${navOpen ? 0 : 1};
                    transform: ${navOpen ? 'translateX(-5px)' : 'translateX(0)'};
                    transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
                    transition-property: opacity, transform;
                `}
            />
            <img
                src={close}
                alt="close menu"
                width="26"
                height="26"
                css={css`
                    grid-area: 1/1;
                    opacity: ${subnav || !navOpen ? 0 : 1};
                    transform: ${!navOpen
                        ? 'translateX(5px)'
                        : subnav
                        ? 'translateX(-5px)'
                        : 'translateX(0)'};
                    transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
                    transition-property: opacity, transform;
                `}
            />
            <img
                src={arrow}
                alt="go back to main menu"
                width="28"
                height="26"
                css={css`
                    grid-area: 1/1;
                    opacity: ${subnav ? 1 : 0};
                    transform: ${subnav ? 'translateX(0)' : 'translateX(5px)'};
                    transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
                    transition-property: opacity, transform;
                `}
            />
        </CircleBtn>
    );
}
