import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import address from '../images/address.png';
import email from '../images/email.svg';
import phone from '../images/layout/phone.png';
import { h3 } from './Headings';

const Container = styled.div`
    background: ${({ theme }) => theme.kindaWhite};
    padding: 128px 16px 64px;
    margin-top: -88px;
    z-index: 1;

    @media (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1024px) {
        padding: 64px 0 64px 32px;
        margin-top: 0;
        background: none;
    }

    @media (min-width: 1280px) {
        padding-left: 64px;
    }

    @media (min-width: 1440px) {
        padding: ${({ contactPage }) => (contactPage ? '64px 0 64px 64px' : '72px 0 104px 72px')};
    }
`;

const Details = styled.div`
    margin-bottom: 64px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    padding: 0 40px;
    max-width: ${({ contactPage }) => (contactPage ? '800px' : '550px')};

    h6 {
        ${h3};
        margin: 0 0 16px;
    }

    @media (min-width: 768px) {
        grid-template-columns: 1fr max-content;
        padding: 0;
    }

    @media (min-width: 1280px) {
        max-width: ${({ contactPage }) => (contactPage ? '700px' : '550px')};
        gap: 48px;
    }

    @media (min-width: 1600px) {
        grid-template-columns: ${({ contactPage }) =>
            contactPage ? '1fr max-content max-content' : '1fr max-content'};
        max-width: ${({ contactPage }) => (contactPage ? '800px' : '620px')};
    }
`;

const Info = styled.a`
    margin: 0;
    display: flex;
    align-items: center;

    > img {
        margin-right: 16px;
        width: ${({ address }) => (address ? '32px' : 'auto')};
        height: ${({ address }) => (address ? '32px' : 'auto')};

        @media (min-width: 768px) {
            width: auto;
            height: auto;
        }
    }
`;
export default function ContactDetails({ contactPage }) {
    return (
        <Container contactPage={contactPage}>
            <Details contactPage={contactPage}>
                <div>
                    <h6>Address</h6>
                    <Info as="p" address>
                        <img src={address} alt="address" href="#map" />
                        5030 Anchor Way Suite 8, 2nd Floor, Antilles Bldg, Gallows Bay
                        Christiansted, VI 00820
                    </Info>
                </div>
                <div>
                    <h6>Call us</h6>
                    <Info href="tel:+13406437673">
                        <img src={phone} alt="phone" />
                        (340) 643-7673
                    </Info>
                </div>
                <div>
                    <h6>E-mail us</h6>
                    <Info href="mailto:+info@landorthodontics.com">
                        <img src={email} alt="email address" />
                        info@landorthodontics.com
                    </Info>
                </div>
            </Details>
            <iframe
                id="map"
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7599.939009522958!2d-64.696!3d17.746076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x940fd7b287ebf8d9!2sLand%20Orthodontics!5e0!3m2!1sen!2sus!4v1641601573816!5m2!1sen!2sus"
                css={css`
                    border: 0;
                    border-radius: 32px;
                    width: 100%;
                    max-width: ${contactPage ? '800px' : '550px'};
                    height: 68vw;
                    max-height: ${contactPage ? '633px' : '400px'};

                    @media (min-width: 768px) {
                        border-radius: 64px;
                    }

                    @media (min-width: 1280px) {
                        max-width: ${contactPage ? '700px' : '550px'};
                    }

                    @media (min-width: 1600px) {
                        max-width: ${contactPage ? '800px' : '620px'};
                    }
                `}
                allowFullScreen=""
                loading="lazy"
            ></iframe>
        </Container>
    );
}
