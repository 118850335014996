import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function Seo({ description, meta, title, keywords, image }) {
    const { site, og } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        keywords
                        siteUrl
                    }
                }
                og: file(relativePath: { eq: "default-og-image.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(quality: 100)
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const theTitle = title
        ? `${title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} | ${
              site.siteMetadata.title
          }`
        : site.siteMetadata.title;
    const theKeywords = keywords || site.siteMetadata.keywords;
    const imageData = image || og.childImageSharp.gatsbyImageData;

    return (
        <Helmet
            htmlAttributes={{ lang: 'en' }}
            title={theTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `keywords`,
                    content: theKeywords,
                },
                {
                    property: `og:title`,
                    content: theTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:url`,
                    content: site.siteMetadata.siteUrl,
                },
                {
                    property: `og:image`,
                    content: imageData.images.fallback.src,
                },
                {
                    property: 'og:image:width',
                    content: `${imageData.width}`,
                },
                {
                    property: 'og:image:height',
                    content: `${imageData.height}`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:image`,
                    content: imageData.images.fallback.src,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: theTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        />
    );
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

export default Seo;
