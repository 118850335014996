import styled from '@emotion/styled';

export const PlainBtn = styled.button`
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    line-height: inherit;
    font: inherit;
    cursor: pointer;
`;
