import styled from '@emotion/styled';

export const navLinks = [
    {
        text: 'braces',
        subMenu: true,
    },
    {
        text: 'invisalign',
        link: '/invisalign/',
    },
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'new patients',
        link: '/new-patients/',
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
];

export const subnavLinks = {
    braces: [
        {
            text: 'braces',
            subMenu: true,
        },
        {
            text: 'kids braces',
            link: '/services/kids-braces/',
        },
        {
            text: 'adult braces',
            link: '/services/adult-braces/',
        },
        {
            text: 'types of braces',
            link: '/services/types-of-braces/',
        },
    ],
};

export const NavLink = styled.a`
    color: ${({ theme }) => theme.white};
    font-size: ${({ subnav }) => (subnav ? '1rem' : '1.25rem')};
    font-weight: ${({ subnav }) => (subnav ? 400 : 700)};
    text-transform: capitalize;
    position: relative;
    margin-top: 72px;
    transition: all 0.3s ease-in-out;

    ::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        margin-left: -32px;
        height: 2px;
        width: 64px;
        border-radius: 5px;
        background: ${({ theme }) => theme.lightTeal};
        box-shadow: 2px 2px 32px ${({ theme }) => theme.darkTeal};
        transform: scaleX(0);
        transition: transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    }

    :hover,
    :focus {
        ::after {
            transform: scaleX(1);
        }
    }

    @media (max-height: 720px) {
        margin-top: 56px;
    }

    @media (min-width: 1024px) {
        color: ${({ theme }) => theme.kindaBlack};
        margin: 0;
    }
`;

export const NavLinkBtn = styled(NavLink)`
    display: flex;
    align-items: center;
    cursor: pointer;

    ::after {
        width: 90px;
        margin-left: -45px;
    }

    > img {
        margin-left: 8px;
        filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(196deg) brightness(103%)
            contrast(100%);
    }

    :hover,
    :focus {
        img {
            filter: invert(80%) sepia(34%) saturate(682%) hue-rotate(120deg) brightness(87%)
                contrast(88%);
        }
    }

    @media (min-width: 1024px) {
        > img {
            filter: none;
        }
    }
`;
