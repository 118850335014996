import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import Select, { components } from 'react-select';
import arrow from '../images/down-arrow.png';
import { PrimaryBtn } from './Buttons/Primary';

export const Form = styled.form`
    padding: 32px;
    background: ${({ theme }) => theme.bg};
    box-shadow: 0px 32px 64px rgba(33, 163, 185, 0.2);
    border-radius: 32px;
    box-shadow: 0px 32px 64px rgba(33, 163, 185, 0.2);
    width: max-content;
    max-width: 90vw;
    margin: 0 auto;
    z-index: 20;
    position: relative;
    overflow: hidden;

    > h3 {
        margin: 0 0 32px;
        text-transform: capitalize;
    }

    @media (min-width: 768px) {
        padding: 72px 56px;
    }

    @media (min-width: 1024px) {
        padding: 32px;
        align-self: flex-start;
        box-shadow: 0px 32px 64px rgba(33, 163, 185, 0.3);
    }

    @media (min-width: 1280px) {
        border-radius: 64px;

        > h3 {
            font-size: 2.1rem;
        }
    }

    @media (min-width: 1600px) {
        > h3 {
            font-size: 2.8rem;
        }
    }
`;

export const Input = styled.input`
    margin-bottom: 10px;
    background: ${({ theme }) => theme.kindaWhite};
    border-radius: 5px;
    padding: 11px 21px;
    display: block;
    width: 246px;
    max-width: 100%;
    color: ${({ theme }) => theme.darkTeal};
    border: none;
    font-size: 0.875rem;
    line-height: 26px;

    :focus,
    :hover {
        outline: 1px solid ${({ theme }) => theme.darkTeal};
    }

    ::placeholder {
        color: #737373;
    }

    :invalid {
        outline-color: #b71801;
    }
`;

export const Label = styled.label`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #252b42;
    margin-bottom: 10px;
    display: inline-block;
`;

export const Submit = styled(PrimaryBtn)`
    display: block;
    margin-top: 32px;
    text-transform: uppercase;
`;

export const Thanks = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${({ theme }) => theme.bg};
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;

    > h1 {
        margin-top: 0;
    }

    > h3 {
        margin-bottom: 0;
    }

    @media (min-width: 1024px) {
        padding: 64px;
    }
`;

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={arrow} alt="dropdown" width={14} height={9} />
        </components.DropdownIndicator>
    );
};

const DropdownStyles = theme => ({
    ...theme,
    borderRadius: 5,
    background: '#F7F5F0',
    colors: {
        ...theme.colors,
        primary: '#21A3B9',
        primary25: '#4ECDC4',
        primary50: '#21A3B9',
    },
});

const customStyles = {
    control: provided => ({
        ...provided,
        padding: '11px 21px',
        background: '#F7F5F0',
        border: 'none',
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: '0 !important',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    valueContainer: provided => ({
        ...provided,
        padding: 0,
    }),
    input: provided => ({
        ...provided,
        padding: '0 !important',
        margin: '0 !important',
    }),

    singleValue: provided => ({
        ...provided,
        color: '#21A3B9',
        fontSize: '0.875rem',
        lineHeight: '26px',
    }),
    placeholder: provided => ({
        ...provided,
        fontSize: '0.875rem',
        lineHeight: '26px',
        color: '#737373',
    }),
    menuList: provided => ({
        ...provided,
        border: '1px solid #21A3B9',
        fontSize: '0.875rem',
        lineHeight: '26px',
        borderRadius: 5,
    }),
};

export const CustomDropdown = props => {
    return (
        <Select
            {...props}
            theme={DropdownStyles}
            components={{ DropdownIndicator }}
            styles={customStyles}
            isSearchable={false}
            css={css`
                margin-bottom: 10px;
                width: 246px;
                max-width: 100%;
            `}
        />
    );
};
