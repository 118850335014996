import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import address from '../../images/address.png';
import wave from '../../images/layout/footer.svg';
import phone from '../../images/layout/phone.png';
import { h3 } from './../Headings';
import { navLinks } from './NavLinks';

const FooterLink = styled(Link)`
    color: ${({ theme }) => theme.white};
    margin-bottom: 8px;
    text-transform: capitalize;
    display: block;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const Info = styled.p`
    margin: 0 0 8px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.white};

    > img {
        margin-right: 16px;
        margin-left: ${({ address }) => (address ? 0 : '7px')};
        width: ${({ address }) => (address ? '32px' : 'auto')};
        height: ${({ address }) => (address ? '32px' : 'auto')};
        filter: brightness(0) invert(1);

        @media (min-width: 768px) {
            margin-right: ${({ address }) => (address ? '16px' : '30px')};
            margin-left: ${({ address }) => (address ? 0 : '20px')};
            width: auto;
            height: auto;
        }
    }
`;

const Flex = styled.div`
    margin-top: 32px;

    @media (min-width: 768px) {
        display: flex;
        max-width: 786px;
        margin: 64px auto 0;

        > div {
            flex-basis: 50%;
        }
    }
`;

const Heading = styled.h6`
    ${h3};
    color: ${({ theme }) => theme.white};
    margin: 0 0 32px;
    text-transform: uppercase;

    @media (max-width: 767px) {
        margin-top: ${({ second }) => (second ? '32px' : 0)};
    }
`;

const Copyright = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    text-align: center;
    background-color: #007e94;
    margin: 0;
    color: ${({ theme }) => theme.white};
    height: 76px;
`;

export default function Footer() {
    return (
        <footer>
            <img
                src={wave}
                alt=""
                css={css`
                    width: 100vw;
                `}
            />
            <div
                css={theme => css`
                    background: ${theme.lightTeal};
                    padding: 64px 16px 186px;
                `}
            >
                <StaticImage
                    src="../../images/layout/logo.png"
                    alt="land orthodontics"
                    layout="fixed"
                    css={css`
                        filter: brightness(0) invert(1);
                        margin: 0 auto 32px;
                    `}
                />
                <Flex>
                    <div>
                        <Heading>navigate</Heading>
                        {navLinks.slice(1).map((link, i) => (
                            <FooterLink to={link.link} key={i}>
                                {link.text}
                            </FooterLink>
                        ))}
                    </div>
                    <div>
                        <Heading second>get in touch</Heading>
                        <Info address>
                            <img src={address} alt="address" />
                            5030 Anchor Way Suite 8, 2nd Floor, Antilles Bldg, Gallows Bay
                            Christiansted, VI 00820
                        </Info>
                        <Info>
                            <img src={phone} alt="phone" />
                            (340) 643-7673
                        </Info>
                    </div>
                </Flex>
            </div>

            <Copyright>
                All Rights Reserved. Land Orthodontics. © {new Date().getFullYear()}
            </Copyright>
        </footer>
    );
}
