import styled from '@emotion/styled';
import { PlainBtn } from './Plain';

export const SecondaryBtn = styled(PlainBtn)`
    display: inline-block;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.black};
    border-radius: 1rem;
    color: ${({ theme }) => theme.black};
    padding: 1rem 2rem;
    text-transform: capitalize;
    font-weight: 700;

    :hover,
    :focus {
        background-color: ${({ theme }) => theme.white};
        border-color: ${({ theme }) => theme.lightTeal};
        color: ${({ theme }) => theme.lightTeal};
    }
`;
