import styled from '@emotion/styled';
import { PlainBtn } from './Plain';

export const PrimaryBtn = styled(PlainBtn)`
    display: inline-block;
    background-color: ${({ theme }) => theme.lightTeal};
    border: 2px solid ${({ theme }) => theme.darkTeal};
    border-radius: 1rem;
    color: ${({ theme }) => theme.white};
    padding: 1rem 2rem;
    font-weight: 700;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    transition-property: border-color, background-color, border-radius;

    :hover,
    :focus {
        background-color: ${({ theme }) => theme.darkTeal};
        border-color: ${({ theme }) => theme.lightTeal};
        border-bottom-right-radius: 0;
    }
`;
