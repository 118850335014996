import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import logo from '../../images/layout/logo.png';
import { SecondaryBtn } from './../Buttons/Secondary';
import DesktopNav from './DesktopNav';
import MobNav from './MobNav';

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 32px;
    left: 0;
    right: 0;
    padding: 0 16px;
    z-index: 100;

    max-width: 1920px;
    margin: 0 auto;

    @media (min-width: 1024px) {
        padding: 0 32px;
    }

    @media (min-width: 1280px) {
        padding: 0 64px;
    }
`;

export default function Nav() {
    const [subnav, setSubnav] = useState('');

    return (
        <Header>
            <Link to="/">
                <img
                    src={logo}
                    alt="land orthodontics"
                    css={css`
                        width: 115px;
                        height: 50px;

                        @media (min-width: 1024px) {
                            width: 230px;
                            height: 100px;
                        }
                    `}
                />
            </Link>
            <MobNav subnav={subnav} setSubnav={setSubnav} />

            <DesktopNav subnav={subnav} setSubnav={setSubnav} />

            <SecondaryBtn
                as="a"
                href="#contact-us"
                css={css`
                    text-transform: uppercase;
                    display: none;

                    @media (min-width: 1440px) {
                        display: block;
                    }
                `}
            >
                book an appointment
            </SecondaryBtn>
        </Header>
    );
}
