import React from 'react';
import { Mousewheel, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper } from 'swiper/react';

export default function Slider({ children, sliderSettings }) {
    return (
        <Swiper
            modules={[Navigation, Mousewheel]}
            centerInsufficientSlides={true}
            grabCursor={true}
            {...sliderSettings}
        >
            {children}
        </Swiper>
    );
}
