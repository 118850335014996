import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import down from '../../images/down-arrow.png';
import close from '../../images/small-close.png';
import { CircleBtn } from '../Buttons/Circle';
import useMountTransition from './../../hooks/useMountTransition';
import { PlainBtn } from './../Buttons/Plain';
import { NavLink, NavLinkBtn, navLinks, subnavLinks } from './NavLinks';

const Nav = styled.nav`
    position: relative;
    display: none;

    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        width: calc(100vw - 400px);
        max-width: 890px;
    }

    @media (min-width: 1280px) {
        width: calc(100vw - 450px);
    }

    @media (min-width: 1440px) and (max-width: 1620px) {
        width: 690px;
    }
`;

const Subnav = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 48px 64px 56px;
    width: 796px;
    max-width: 100%;
    height: 134px;
    background: ${({ theme }) => theme.white};
    box-shadow: 0px 32px 64px rgba(33, 163, 185, 0.2);
    border-radius: 32px;
    z-index: 50;
    position: absolute;
    left: 0;
    bottom: -188px;
    transform: ${({ subnav }) => (subnav ? 'translateY(0)' : 'translateY(-600px)')};
    transition: transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    @media (min-width: 1440px) and (max-width: 1620px) {
        width: 690px;
    }
`;

const Bg = styled.div`
    position: absolute;

    ::before {
        opacity: ${({ subnav, subnavTransition }) => (subnav && subnavTransition ? 0.3 : 0)};
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 40;
        background: ${({ theme }) => theme.darkTeal};
        transition: opacity 0.15s ease-in-out;
    }
`;

const CloseBtn = styled(CircleBtn)`
    position: absolute;
    bottom: -112px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;

    :hover,
    :focus {
        box-shadow: 0px 32px 64px rgba(33, 163, 185, 0.2);
    }
`;

export default function DesktopNav({ subnav, setSubnav }) {
    const subnavTransition = useMountTransition(subnav, 150);
    const closeSubnav = () => {
        setSubnav('');
    };
    return (
        <Nav>
            {navLinks.map((link, i) =>
                link.subMenu ? (
                    <NavLinkBtn
                        as={PlainBtn}
                        key={i}
                        onClick={() => setSubnav(subnav => (subnav === link.text ? '' : link.text))}
                    >
                        {link.text}
                        <img src={down} alt="braces menu" />
                    </NavLinkBtn>
                ) : (
                    <NavLink as={Link} to={link.link} key={i}>
                        {link.text}
                    </NavLink>
                )
            )}

            <Subnav subnav={subnav}>
                {subnavLinks[subnav] &&
                    subnavLinks[subnav].map((link, i) =>
                        link.subMenu ? (
                            ''
                        ) : (
                            <NavLink as={Link} to={link.link} key={i}>
                                {link.text}
                            </NavLink>
                        )
                    )}
                <CloseBtn onClick={closeSubnav}>
                    <img src={close} alt="close sub navigation" />
                </CloseBtn>
            </Subnav>

            {(subnav || subnavTransition) && (
                <Bg onClick={closeSubnav} subnav={subnav} subnavTransition={subnavTransition} />
            )}
        </Nav>
    );
}
