import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import { PrimaryBtn } from './Buttons/Primary';
import { CustomDropdown, Form, Input, Label, Thanks } from './FormComponents';

const FormContainer = styled(Form)`
    display: ${({ desktop }) => (desktop ? 'none' : 'block')};

    @media (min-width: 1024px) {
        display: ${({ desktop }) => (desktop ? 'block' : 'none')};
        transform: translateY(-20%);
    }

    @media (min-width: 1280px) {
        padding: 96px 61px;
        width: max-content;
    }
`;

export default function AppointmentForm({ desktop }) {
    const timeOptions = [
        { value: '5pm', label: '5:00 pm' },
        { value: '7pm', label: '7:00 pm' },
    ];

    const dateRef = useRef(null);

    const [submit, setSubmit] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmit(true);

        const data = Object.fromEntries(new FormData(e.target));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        e.target.reset();
    };
    return (
        <FormContainer desktop={desktop} onSubmit={handleSubmit}>
            <h3>Book Appointment</h3>
            <Label htmlFor="name">Name *</Label>
            <Input type="text" id="name" name="name" placeholder="John Doe" required />
            <Label htmlFor="phone">Phone *</Label>
            <Input type="tel" id="phone" name="phone" placeholder="(222) 222 2222" required />
            <Label htmlFor="email">Email address *</Label>
            <Input type="email" id="email" name="email" placeholder="example@gmail.com" required />
            <Label htmlFor="date">Preferred Date</Label>
            <Input
                id="date"
                name="date"
                placeholder="dd/mm/yyyy"
                ref={dateRef}
                onFocus={() => (dateRef.current.type = 'date')}
                css={css`
                    height: 48px;
                `}
            />
            <Label htmlFor="time" id="preferredTime">
                Preferred Time
            </Label>
            <CustomDropdown
                options={timeOptions}
                id="time"
                name="time"
                placeholder={'4:00 pm'}
                aria-labelledby="preferredTime"
            />
            <PrimaryBtn
                as="input"
                type="submit"
                value="BOOK AN APPOINTMENT"
                css={css`
                    text-transform: uppercase;
                    margin-top: 32px;
                `}
            />
            <Thanks submit={submit}>
                <h1>Thank you</h1>
                <h3>Someone will be right with you shortly!</h3>
            </Thanks>
        </FormContainer>
    );
}
