import styled from '@emotion/styled';
import { PlainBtn } from './Plain';

export const CircleBtn = styled(PlainBtn)`
    background: ${({ theme }) => theme.bg};
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: box-shadow 0.3s ease-in-out;
    z-index: 1;

    ::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: ${({ theme }) => theme.gradient};
        z-index: -1;
        border-radius: 50%;
        opacity: ${({ active }) => (active ? 1 : 0)};
        transition: opacity 0.3s ease-in-out;
    }

    :hover,
    :focus {
        box-shadow: 0px 32px 64px rgba(33, 163, 185, 0.2);
    }
`;
