import styled from '@emotion/styled';
import React from 'react';
import AppointmentForm from '../AppointmentForm';
import ContactDetails from '../ContactDetails';

const Section = styled.section`
    padding-top: 32px;
    background: ${({ theme }) => theme.white};

    > h1 {
        margin: 0 0 32px 16px;
    }

    @media (min-width: 1024px) {
        > h1 {
            margin-left: 32px;
        }
    }

    @media (min-width: 1366px) {
        padding-top: 139px;

        > h1 {
            max-width: 1280px;
            margin: 0 auto 58px;
        }
    }

    @media (min-width: 1600px) {
        padding-bottom: 162px;

        > h1 {
            max-width: 1440px;
            margin: 0 calc((100vw - 1440px) / 3) 58px auto;
            padding-left: 12px;
        }
    }
`;

const Flex = styled.div`
    @media (min-width: 1024px) {
        display: flex;
        background-color: ${({ theme }) => theme.kindaWhite};
    }

    @media (min-width: 1280px) {
        max-width: 1280px;
        margin: 0 auto;
        border-radius: 64px;
    }

    @media (min-width: 1600px) {
        max-width: 1440px;
        border-radius: 64px;
        margin: 0 calc((100vw - 1440px) / 3) 0 auto;
    }
`;
export default function ContactUs() {
    return (
        <Section id="contact-us">
            <h1>
                Contact Us/
                <br />
                Appointments
            </h1>
            <Flex>
                {/* I didn't use flex order instead used two forms for accessibility reasons */}
                <AppointmentForm />
                <ContactDetails />
                <AppointmentForm desktop />
            </Flex>
        </Section>
    );
}
