import styled from '@emotion/styled';
import React from 'react';
import { SwiperSlide } from 'swiper/react';
import quote from '../../images/layout/quote.svg';
import star from '../../images/layout/star.svg';
import arrow from '../../images/right-arrow.png';
import Slider from '../Slider';
import { IconBtn } from './../Buttons/Icon';
import { h3 } from './../Headings';
import { padding } from './GlobalStyles';

const Section = styled.section`
    padding: 64px 0px;

    .swiper {
        overflow: visible;
    }

    .swiper-slide {
        max-width: 364px;
        opacity: 0.3;
        transition: opacity 0.3s ease-in;

        :first-of-type {
            margin-left: 12px;
        }

        :last-of-type {
            margin-right: 12px;
        }
    }

    .swiper-slide-active {
        opacity: 1;

        & + .swiper-slide {
            opacity: 0.6;
        }
    }
`;

const Top = styled.div`
    ${padding};
    text-align: center;
    max-width: 1200px;
    margin: 0 auto 64px;

    > h1 {
        margin: 0 0 32px;
    }

    > p {
        ${h3};
        margin: 32px 0 0;
        color: ${({ theme }) => theme.lightTeal};
    }

    > div {
        display: flex;
        justify-content: center;
    }

    @media (min-width: 1024px) {
        margin-bottom: 80px;
    }
`;

const Review = styled.div`
    padding: 32px 32px 64px;
    background: ${({ theme }) => theme.bg};
    box-shadow: 0px -32px 64px rgba(255, 255, 255, 0.16), 0px 32px 64px rgba(33, 163, 185, 0.16),
        inset 0px 16px 64px rgba(255, 255, 255, 0.25);
    border-radius: 64px;
    transition: box-shadow 0.3s ease-in-out;

    ::before {
        content: url(${quote});
        top: 0;
        left: 0;
    }

    :hover {
        box-shadow: 0px -32px 64px rgba(255, 255, 255, 0.16), 0px 32px 64px rgba(33, 163, 185, 0.3),
            inset 0px 16px 64px rgba(255, 255, 255, 0.4);
    }
`;

const Text = styled.p`
    font-size: 1.25rem;
    margin: 32px 0;
    padding-bottom: 32px;
    border-bottom: 1px solid ${({ theme }) => theme.black};

    + p {
        margin: 0;
        color: ${({ theme }) => theme.darkTeal};
        text-transform: capitalize;
        font-weight: 700;
    }
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    padding: 0 8px;
    margin: 64px auto 0;

    button {
        z-index: 10;
    }

    > button:first-of-type {
        > img {
            transform: scaleX(-1);
        }
    }

    @media (min-width: 1024px) {
        margin-right: 56px;
        margin: 64px 64px 0 auto;
    }
`;

const testimonials = [
    {
        text: "I take my daughter to see Dr. Land once a month right now she just got braces. We love the staff and him they're all very friendly and sweet! We love them all but my daughter loves going to see Danita! She always makes her feel comfortable and is extremely patient with her.",
        person: 'sandra wood',
    },
    {
        text: "Dr LAND IS A GREAT DOCTOR VERY KIND AND COMPASSIONATE. HIS STAFF IS VERY FRIENDLY AND HAS ALWAYS MADE MY DAUGHTER'S VISITS ENJOYABLE. THEY REALLY CARE ABOUT THEIR PATIENTS",
        person: 'Nikki Renshaw',
    },
    {
        text: 'Land Orthodontics has the friendliest staff. They welcome you and treat you like family. My son has been a patient there for over two years and we look forward to going to every appointment. I can’t say enough about them. They truly are remarkable.',
        person: 'Christina Hodges',
    },
    {
        text: 'I used dr land and his amazing staff for my braces and I have to say the results were amazing! I always got treated very well each visit and got nothing but excellent treatment! Dr land the receptionist and all the assistants were AWESOME! Especially Theresa! I recommend everyone using them for braces!',
        person: 'Victoria Bryant',
    },
    {
        text: "Dr Land was awesome. Staff is courteous. My 4 year old came along and he is so hyper and loud sometimes. The staff was not annoyed. My 10 year old daughter will be getting braces soon. She's excited about it so I'm happy. Thanks Dr Land and staff for making us feel comfortable and welcome. Can't wait to begin this process and long journey.",
        person: 'Kelly Watson',
    },
    {
        text: 'Dr. Land and Mrs. Land were so warm, jolly, and welcoming. Dr. Land spent time getting to know my son a bit and allowed him to feel much more at ease about getting braces. If we have to go through this process, I am happy to have such a knowledgeable, professional and kind doctor. I highly recommend him!',
        person: 'Loni McGackin',
    },
];

export default function Reviews() {
    const sliderSettings = {
        navigation: {
            nextEl: '.review-slider-next',
            prevEl: '.review-slider-prev',
        },
        slidesPerView: 'auto',
        autoHeight: false,
        spaceBetween: 16,
        breakpoints: {
            1024: {
                spaceBetween: 32,
            },
            1366: {
                spaceBetween: 64,
            },
        },
        centeredSlides: true,
        loop: true,
        loopedSlides: 3,
    };

    return (
        <Section>
            <Top>
                <h1>Our Doctors and staff has earned over 210+ Reviews on Google!</h1>
                <div>
                    <img src={star} alt="4.3 rating" />
                    <img src={star} alt="4.3 rating" />
                    <img src={star} alt="4.3 rating" />
                    <img src={star} alt="4.3 rating" />
                    <img src={star} alt="4.3 rating" />
                </div>
                <p>Average rating 4.3</p>
            </Top>
            <Slider sliderSettings={sliderSettings}>
                {testimonials.map((story, i) => (
                    <SwiperSlide key={i}>
                        <Review>
                            <Text>{story.text}</Text>
                            <p>{story.person}</p>
                        </Review>
                    </SwiperSlide>
                ))}
            </Slider>
            <Buttons>
                <IconBtn className="review-slider-prev">
                    <img src={arrow} alt="previous slide" />
                </IconBtn>
                <IconBtn className="review-slider-next">
                    <img src={arrow} alt="next slide" />
                </IconBtn>
            </Buttons>
        </Section>
    );
}
