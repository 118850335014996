import { ThemeProvider } from '@emotion/react';
import React from 'react';
import ContactUs from './ContactUs';
import Footer from './Footer';
import GlobalStyles, { theme } from './GlobalStyles';
import Nav from './Nav';
import Reviews from './Reviews';

const Layout = ({ children, contactPage }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Nav />
            <main>{children}</main>
            {contactPage ? '' : <ContactUs />}
            <Reviews />
            <Footer />
        </ThemeProvider>
    );
};

export default Layout;
