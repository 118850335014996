import styled from '@emotion/styled';
import { PlainBtn } from './Plain';

export const IconBtn = styled(PlainBtn)`
    position: relative;
    border-radius: 32px;
    overflow: hidden;
    width: 128px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0px 16px 32px rgba(33, 163, 185, 0.3);

    ::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: -2;
        border-radius: inherit;
        background: ${({ theme }) => theme.gradient};
        transition: all 0.3s ease-in-out;
    }

    ::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 1px;
        top: 1px;
        right: 1px;
        bottom: 1px;
        background: white;
        border-radius: 32px;
    }

    :hover,
    :focus {
        box-shadow: 0px 16px 32px rgba(33, 163, 185, 0.4);
        ::before {
            opacity: 1;
        }
    }
`;
