import { css, Global } from '@emotion/react';
import '@fontsource/poppins';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import emotionNormalize from 'emotion-normalize';
import React from 'react';

export const theme = {
    white: '#FFFFFF',
    kindaWhite: '#F7F5F0',
    darkTeal: '#21A3B9',
    lightTeal: '#4ECDC4',
    kindaBlack: '#051722',
    black: '#000000',
    gradient:
        'radial-gradient(156.21% 190.74% at 100% 128.31%, #4ECDC4 0%, #4ECDC4 5.21%, #21A3B9 42.19%, #80E7DF 90.1%, #4ECDC4 99.99%);',
    bg: '#F6F9F8',
};

export const padding = css`
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 1024px) {
        padding-left: 32px;
        padding-right: 32px;
    }
`;

export const py32 = css`
    @media (min-width: 1024px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
`;

export default function GlobalStyles({ navOpen }) {
    return (
        <Global
            styles={theme => css`
                ${emotionNormalize}
                html {
                    overflow-y: ${navOpen ? 'hidden' : 'auto'};
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    background-color: ${theme.bg};
                    color: ${theme.kindaBlack};
                    font-family: 'Poppins', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 1.619;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1,
                h2,
                h3,
                h4,
                h5 {
                    font-weight: 700;
                    color: ${theme.darkTeal};
                }

                h1 {
                    font-size: 2.25rem;
                }

                h2 {
                    font-size: 1.75rem;
                }

                h3 {
                    font-size: 1.31rem;
                }

                p {
                    font-size: 1rem;
                }

                a {
                    text-decoration: none;
                    color: ${theme.kindaBlack};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul {
                    padding-inline-start: 30px;
                }

                @media (min-width: 1280px) {
                    h1 {
                        font-size: 3.125rem;
                    }

                    h2 {
                        font-size: 2.1rem;
                    }

                    h3 {
                        font-size: 1.4rem;
                    }
                }

                @media (min-width: 1440px) {
                    p,
                    li {
                        font-size: 1.125rem;
                    }
                }

                @media (min-width: 1600px) {
                    h1 {
                        font-size: 4rem;
                    }

                    h2 {
                        font-size: 2.45rem;
                    }

                    h3 {
                        font-size: 1.5rem;
                    }

                    p,
                    li {
                        font-size: 1.25rem;
                    }
                }
            `}
        />
    );
}
